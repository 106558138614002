import React, { useEffect } from 'react';
import { Col, Row, Button } from 'antd';
import { gutter, showErrorsIfInvalidOrPerformAction } from '@src/utils/helpers/orders';
import { Field, Form } from 'react-final-form';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import createDecorator from 'final-form-focus';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import WhenFieldChanges from '@src/components/input/WhenFieldChanges';
import Loader from '@src/components/Loader';
import {
    required,
    conditionRequired,
    requiredAtLeastOneField,
    maxLength,
} from '@src/utils/helpers/validationRules';
import { trimWhitespacesInObjectFields } from '@src/utils/helpers/normalize';

const focusOnError = createDecorator();

let validationMode = null;

const mutateValue = ([name], state, { changeValue }) => {
    // change the value to the same value, thus
    // triggering a revalidation of the same value
    changeValue(state, name, value => value);
};

const SelectPropertyForm = ({
    goToSearchStep,
    goToEditStep,
    formData = {},
    counties = [],
    municipalities = [],
    setSearchParams,
    searchPropertyHandler,
    isSearching,
    searchResults,
    setForm,
    isContinuation,
    nextWithoutSearchButtonRef,
    validateSearchOrderFormButtonRef,
}) => {
    useEffect(() => {
        if (searchResults && searchResults.length > 0 && !isContinuation) goToSearchStep();
    }, [searchResults]);

    const nextStepWithoutSearch = (form, values, mutateValue) => {
        validationMode = 'withoutSearch';
        mutateValue('block');
        const trimmedValues = trimWhitespacesInObjectFields(values);
        showErrorsIfInvalidOrPerformAction(form, () => {
            setSearchParams(trimmedValues);
            goToEditStep();
        });
    };

    const nextStepWithSearch = (form, values, mutateValue) => {
        validationMode = 'withSearch';
        mutateValue('block');
        const trimmedValues = trimWhitespacesInObjectFields(values);
        showErrorsIfInvalidOrPerformAction(form, () => {
            searchPropertyHandler(trimmedValues).then(() => {
                setSearchParams(trimmedValues);
            });
        });
    };

    const validateForm = (form, mutateValue) => {
        validationMode = 'withoutSearch';
        mutateValue('block');
        showErrorsIfInvalidOrPerformAction(form, () => {});
    };

    return (
        <Loader show={isSearching}>
            {
                searchResults
                && searchResults.length === 0
                && (
                    <div className="error-section">
                        No qualified property was found.
                    </div>
                )
            }
            <Form
                onSubmit={setSearchParams}
                decorators={[focusOnError]}
                initialValues={formData}
                mutators={{ mutateValue }}
                validate={({
                    block,
                    lot,
                    mainAddress,
                    municipality,
                    owner,
                    qualifier,
                }) => {
                    const result = {};
                    switch (validationMode) {
                        case 'withoutSearch':
                            result.block = conditionRequired(qualifier, block) || maxLength(16)(block);
                            result.lot = conditionRequired(qualifier, lot) || maxLength(16)(lot);
                            result.qualifier = maxLength(32)(qualifier);
                            result.mainAddress = required(mainAddress) || maxLength(128)(mainAddress);
                            result.municipality = required(municipality);
                            result.owner = maxLength(64)(owner);
                            break;
                        case 'withSearch':
                            if (qualifier) {
                                result.qualifier = maxLength(32)(qualifier);
                                result.block = required(block) || maxLength(16)(block);
                                result.lot = required(lot) || maxLength(16)(lot);
                                result.mainAddress = maxLength(128)(mainAddress);
                                result.owner = maxLength(64)(owner);
                            } else {
                                const fields = [block, mainAddress, owner];
                                result.block = requiredAtLeastOneField(fields) || maxLength(16)(block);
                                result.mainAddress = requiredAtLeastOneField(fields) || maxLength(128)(mainAddress);
                                result.owner = requiredAtLeastOneField(fields) || maxLength(64)(owner);
                                result.lot = maxLength(16)(lot);
                            }
                            break;
                        default: {
                            result.block = maxLength(255)(block);
                            result.lot = maxLength(16)(lot);
                            result.qualifier = maxLength(32)(qualifier);
                            result.mainAddress = maxLength(128)(mainAddress);
                            result.owner = maxLength(64)(owner);
                            break;
                        }
                    }
                    if (!Object.values(result).some(value => value)) {
                        validationMode = null;
                    }

                    return result;
                }}
            >
                {
                    params => {
                        const {
                            handleSubmit,
                            form,
                            form: { mutators: { mutateValue } },
                            values,
                        } = params;

                        useEffect(() => {
                            setForm(form);
                        }, []);
                        const countyOptions = counties.map(({ id, name }) => (
                            { label: name, value: id.toString() }
                        ));

                        const municipalityOptions = values.county && municipalities[values.county]
                            ? municipalities[values.county].map(({ id, name }) => (
                                { label: name, value: id.toString() }
                            ))
                            : [];

                        return (
                            <form onSubmit={handleSubmit} className="property-search-form">
                                <div className="formBlock">
                                    <Row gutter={gutter}>
                                        <Col xs={24} md={24} lg={12}>
                                            <Field
                                                name="county"
                                                component={InputGroupAntSelectAdapter}
                                                controlId="county"
                                                labelText="County"
                                                options={countyOptions}
                                                isRequired
                                                showSearch
                                                optionFilterProp="label"
                                                filterOption
                                                disabled={isContinuation}
                                            />
                                            <WhenFieldChanges
                                                field="county"
                                                set="municipality"
                                                to={municipalityOptions
                                                    .find(({ value }) => (
                                                        value === values.municipality
                                                        || value === formData.municipality
                                                    ))
                                                    ? (values.municipality || formData.municipality)
                                                    : ''}
                                            />
                                        </Col>
                                        <Col xs={24} md={24} lg={12}>
                                            <Field
                                                name="municipality"
                                                component={InputGroupAntSelectAdapter}
                                                controlId="municipality"
                                                labelText="Municipality"
                                                options={municipalityOptions}
                                                showSearch
                                                optionFilterProp="label"
                                                filterOption
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={gutter}>
                                        <Col xs={24} md={24} lg={12}>
                                            <Field
                                                name="block"
                                                component={InputGroupAntInputAdapter}
                                                controlId="block"
                                                labelText="Block"
                                                disabled={isContinuation}
                                            />
                                        </Col>
                                        <Col xs={24} md={24} lg={12}>
                                            <Field
                                                name="lot"
                                                component={InputGroupAntInputAdapter}
                                                controlId="lot"
                                                labelText="Lot"
                                                disabled={isContinuation}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={gutter}>
                                        <Col xs={24} md={24} lg={12}>
                                            <Field
                                                name="qualifier"
                                                component={InputGroupAntInputAdapter}
                                                controlId="qualifier"
                                                labelText="Qualifier"
                                                disabled={isContinuation}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={gutter}>
                                        <Col xs={24} md={24} lg={12}>
                                            <Field
                                                name="mainAddress"
                                                component={InputGroupAntInputAdapter}
                                                controlId="mainAddress"
                                                labelText="Street Address"
                                                disabled={isContinuation}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={gutter}>
                                        <Col xs={24} md={24} lg={12}>
                                            <Field
                                                name="owner"
                                                component={InputGroupAntInputAdapter}
                                                controlId="owner"
                                                labelText="Owner"
                                                disabled={isContinuation}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <Button
                                    htmlType="button"
                                    id="next-without-search-button"
                                    ref={nextWithoutSearchButtonRef}
                                    className="btn btn-default next-without-search pull-right"
                                    onClick={() => nextStepWithoutSearch(form, values, mutateValue)}
                                >
                                    Next Without Search
                                </Button>
                                <Button
                                    id="search-for-property"
                                    className="btn btn-primary pull-right right-buffer-xs"
                                    htmlType="submit"
                                    type="primary"
                                    onClick={() => nextStepWithSearch(form, values, mutateValue)}
                                    disabled={isContinuation}
                                >
                                    Search for Property
                                </Button>
                                <Button
                                    value="Validate form"
                                    id="validate-search-for-property-form-button"
                                    ref={validateSearchOrderFormButtonRef}
                                    hidden
                                    htmlType="button"
                                    onClick={() => validateForm(form, mutateValue)}
                                >
                                    Validate form
                                </Button>
                            </form>
                        );
                    }
                }
            </Form>
        </Loader>
    );
};

export default SelectPropertyForm;
